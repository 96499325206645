<template>
  <b-container  fluid>
    <div id="app" style="width:50%; margin:auto; background: white; padding: 20px;">asdsds
    </div>
  </b-container>
</template>

<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'

export default {
  name: 'Website',
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || ''
    }
  },
  methods: {
    checkToken () {
      const token = {
        token: this.$route.params.id
      }
      Axios.post(API.API_CHECK_COMPANY_TOKEN, token)
        .then((response) => {
          if (response.data.status === 'fail') {
            this.$router.push('/pages/error/404')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  },
  mounted () {
    this.checkToken()
  }
}
</script>

<style scoped>

</style>
